import { Accordion, Card, Image, Modal, Table } from "react-bootstrap";
import Img15 from "../../../../assets/img/integration-modals/15.png";
import { useAppContext } from "providers/AppProvider";
import { useState } from "react";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import CardNote from "../CardNote";

export default function SyncDevicesAccordion({ activeKeys, toggleAccordionItem }) {

  const [selectedImage, setSelectedImage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const {
    config: { isDark }
  } = useAppContext();

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const [innerActiveKeys02, setInnerActiveKeys02] = useState([]);

  const toggleInnerAccordionItem = (key) => {
    setInnerActiveKeys02(prevKeys =>
      prevKeys.includes(key) ?
        prevKeys.filter(k => k !== key)
        : [...prevKeys, key]
    );
  };


  return (
    <Accordion.Item eventKey="2">
      <Accordion.Header onClick={() => toggleAccordionItem("2")}>
        <p className="m-0 fs-7">Configurar sincronização de dispositivos</p>
      </Accordion.Header>
      <Accordion.Body>
        <p className="mb-3">
          O próximo passo é configurar a sincronização de dispositivos entre o Datto RMM e MSP Desk.
          No card setup de integração, clique na aba <strong> Dispositivos</strong>.
        </p>
        <CardNote
          text={`É considerado uma boa prática configurar o mapeamento de sites da cliente antes de habilitar a
              sincronização de dispositivos. Configurar a sincronização
              de dispositivos antes de finalizar o site do Datto RMM para o cliente do MSP Desk pode levar a itens de
              configuração duplicados no MSP Desk se o mapeamento de sites for alterado ou se dispositivos mudarem de site,
              uma vez que os ativos no MSP Desk não são excluídos quando um dispositivo é movido ou deletado
              no Datto RMM.`}
        />
        <Image
          fluid
          rounded
          className="mb-3 ms-3"
          src={Img15}
          alt="Aba Dispositivos"
          style={{ cursor: 'pointer', width: '7vw', height: '70px', border: '1px solid #CCCCCB' }}
          onClick={() => handleImageClick(Img15)}
        />
        <ol>
          <li className="ms-3">
            <p className="mb-3">
              Você pode ativar ou desativar o toggle <strong> Sincronizar dispositivos</strong> a qualquer momento para controlar se a sincronização
              de dispositivos está
              ativa ou não.
            </p>
            <CardNote
              text={`Você pode desejar manter esse toggle desligado enquanto trabalha na sua configuração e salva suas alterações.
                  Depois, você pode ativar o toggle sempre que estiver pronto para iniciar oficialmente a sincronização de
                  dispositivos com suas atualizações.`}
            />
          </li>
          <li className="ms-3">
            <p className="mb-3">
              Especifique como o Datto RMM deve sincronizar as informações do dispositivo com os ativos do MSP Desk.
              Você pode ativar ou desativar as seguintes opções:
            </p>
            <ul className="mb-3">
              <li>
                <strong> Sobrescrever o Nome quando for diferente do Nome do Host do RMM
                </strong> (selecionado por padrão)
              </li>
            </ul>
          </li>
          <li className="ms-3">
            <p className="mb-3">
              Em seguida, mapeie os tipos de dispositivos Datto RMM para tipos de ativos do MSP Desk.
            </p>
            <Table
              className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`}
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>Campo</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tipo de ativo do MSP Desk</td>
                  <td>
                    <p className="mb-3">
                      Tipo de ativo conforme exibido no MSP Desk.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Tipo de dispositivo Datto RMM</td>
                  <td>Opcionalmente, selecione um tipo de dispositivo do Datto RMM correspondente para cada tipo de ativo
                    do MSP Desk ou selecione <strong> Não mapeado</strong> para os tipos de ativo que você não deseja sincronizar.
                    O menu suspenso contém todos os tipos
                    de ativos conforme configurado no MSP Desk.</td>
                </tr>

              </tbody>
            </Table>
            <li className="ms-2">
              <p className="mb-4">
                Quando terminar, clique em <strong> Salvar</strong> no canto superior direito para salvar as configurações de ambos
                os toggles citados. No canto inferior direito clique em <strong> Salvar </strong>
                para preservar suas alterações e
                enviar sua configuração de mapeamento para o banco de dados{/* e iniciar a sincronização */}.
              </p>
            </li>
          </li>
        </ol>
        <p className="fs-7 mb-3">
          Lógica e frequência de sincronização de dispositivos.
        </p>
        <Accordion activeKey={innerActiveKeys02} className="col-lg-12">
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => toggleInnerAccordionItem("0")}>
              Lógica de sincronização de dispositivo
            </Accordion.Header>
            <AccordionBody>
              <p>
                O MSP Desk tentará encontrar um ativo correspondente no Datto RMM aplicando a seguinte lógica
                de sincronização de dispositivos:
              </p>
              <ol>
                <li>
                  Procure um ativo no MSP Desk que tenha um mapeamento correspondente para um dispositivo do Datto RMM.
                  <ul className="mb-2">
                    <li style={{ listStyleType: 'lower-alpha' }} className="mt-2 mb-2">
                      Se um mapeamento for encontrado, sincronize o dispositivo.
                    </li>
                    <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                      Se nenhum mapeamento for encontrado, vá para o próximo passo.
                    </li>
                  </ul>
                </li>
                <li>
                  Procure pelo hostname.
                  <ul className="mb-2">
                    <li style={{ listStyleType: 'lower-alpha' }} className="mt-2 mb-2">
                      Se um hostname correspondente for encontrado, sincronize o dispositivo.
                    </li>
                    <li className="mb-2" style={{ listStyleType: 'lower-alpha' }}>
                      Se nenhum hostname for encontrado, vá para o próximo passo.
                    </li>
                  </ul>
                </li>
                <li>
                  Procure um ativo do MSP Desk que tenha o mesmo número de série que o dispositivo do Datto RMM.
                  <ul>
                    <li style={{ listStyleType: 'lower-alpha' }} className="mb-2 mt-2">
                      Se um número de série for encontrado, sincronize o dispositivo.
                    </li>
                    <li style={{ listStyleType: 'lower-alpha' }} className="mb-2">
                      Se nenhum número de série for encontrado, vá para o próximo passo.
                    </li>
                  </ul>
                </li>
                <li className="mb-3">
                  <p className="mb-2">
                    Crie um novo ativo do MSP Desk, em seguida mapeie e sincronize o dispositivo.
                  </p>
                </li>
              </ol>
            </AccordionBody>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header onClick={() => toggleInnerAccordionItem("1")}>
              Frequência de sincronização de dispositivo
            </Accordion.Header>
            <AccordionBody>
              <p className="fs-8">
                Novos dispositivos (não sincronizados) serão sincronizados imediatamente após a realização de uma auditoria inicial.
              </p>
              <p className="fs-8">
                Para dispositivos existentes (sincronizados anteriormente), uma sincronização de dispositivo será acionada uma vez a cada 24 horas
                às 03:00, horário de Brasília.
                Se, no momento da sincronização, o mapeamento existente não for mais válido (por exemplo, o ativo foi desativado,
                os detalhes foram alterados, etc.), a lógica de sincronização do dispositivo será reaplicada.
              </p>
            </AccordionBody>
          </Accordion.Item>
        </Accordion>
      </Accordion.Body>
      <Modal
        show={modalShow}
        onHide={handleCloseModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClick={handleCloseModal}
      >
        <Modal.Body onClick={(e) => e.stopPropagation()}>
          <img
            src={selectedImage}
            alt="Imagem Ampliada"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Modal.Body>
      </Modal>
    </Accordion.Item>
  )
}