import { Accordion, Card, Image, Modal, Table } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import Img17 from "../../../../assets/img/integration-modals/17.png";
import Img18 from "../../../../assets/img/integration-modals/18.png";
import RemoverSelecionados from "../../../../assets/img/integration-modals/RemoverSelecionados.jpg";
import { useState } from "react";
import { useAppContext } from "providers/AppProvider";
import CardNote from "../CardNote";

export default function TicketConfigAccordion({ activeKeys, toggleAccordionItem }) {

  const [modalShow, setModalShow] = useState(false);
  const [modalShowLg, setModalShowLg] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const {
    config: { isDark }
  } = useAppContext();

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalShow(true);
  };
  const handleImageClickLg = (image) => {
    setSelectedImage(image);
    setModalShowLg(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };
  const handleCloseModalLg = () => {
    setModalShowLg(false);
  };

  const [innerActiveKeys02, setInnerActiveKeys02] = useState([]);

  const toggleInnerAccordionItem = (key) => {
    setInnerActiveKeys02(prevKeys =>
      prevKeys.includes(key)
        ? prevKeys.filter(k => k !== key)
        : [...prevKeys, key]
    );
  };

  return (
    <Accordion.Item eventKey="4">
      <Accordion.Header onClick={() => toggleAccordionItem("4")}>
        <p className="m-0 fs-7">Configurar configurações de tickets</p>
      </Accordion.Header>
      <AccordionBody>
        <p className="mb-2">
          No cartão de configuração da integração, após configurar o mapeamento de prioridade de alerta, você pode configurar as
          configurações de tickets na aba <strong> Tickets</strong>.
        </p>
        <p className="mb-3">
          Você pode ativar ou desativar a alternância <strong> Habilitar Sincronização de Tickets</strong> a qualquer momento para controlar
          se a sincronização
          de tickets está ativa ou não. Sua configuração selecionada será salva e persistirá na próxima vez que a página for acessada, sem a
          necessidade de clicar no botão <strong> Salvar</strong>.
        </p>
        <CardNote
          text={`Você pode desejar manter essa alternância desativada enquanto trabalha na sua configuração e salva suas alterações.
              Depois, você pode ativar a alternância sempre que estiver pronto para iniciar oficialmente a sincronização de tickets
              com suas atualizações.`}
        />
        <Accordion activeKey={innerActiveKeys02} className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => toggleInnerAccordionItem("0")}>
              Seção de configurações de tickets
            </Accordion.Header>
            <AccordionBody>
              <Image
                rounded
                className="mb-4 ms-2"
                src={Img17}
                alt="Aba Definições de Ticket - Seção Configurações de Ticket"
                style={{ cursor: 'pointer', width: '13vw', height: '70px', border: '1px solid #CCCCCB' }}
                onClick={() => handleImageClick(Img17)}
              />
              <Table className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`} striped bordered hover>
                <thead>
                  <tr>
                    <th>Campo</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Resolver ticket quando o alerta for resolvido</td>
                    <td>
                      Defina o ticket como concluído quando o alerta for resolvido no Datto RMM.
                    </td>
                  </tr>
                  <tr>
                    <td>Resolver alerta quando o ticket for resolvido</td>
                    <td>
                      Defina o alerta como concluído no Datto RMM quando o ticket for resolvido no MSP Desk.
                    </td>
                  </tr>
                  <tr>
                    <td>Agrupar alertas em um único ticket se tiverem o mesmo problema para o mesmo dispositivo</td>
                    <td>
                      Ative ou desative o toggle para agrupar esses alertas em um único ticket ou optar por não agrupar esses alertas em um único ticket, respectivamente. Você mapeará os tipos de problema na seção subsequente, Configurações Padrão de Ticket.
                      <br />
                      Você pode definir o limite de tempo, em minutos, em que o agrupamento ocorrerá. Insira um número no campo.
                    </td>
                  </tr>
                  <tr>
                    <td>Origem Padrão do Ticket</td>
                    <td>
                      No menu suspenso, selecione a Origem padrão que você gostaria de atribuir a todos os tickets de alerta criados no MSP Desk pelo Datto RMM.
                    </td>
                  </tr>
                  <tr>
                    <td>Contato padrão do ticket</td>
                    <td>
                      <p className="mb-2">
                        Comece a digitar para pesquisar por nome, os contatos da organização e escolha para definir o contato padrão do ticket.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </AccordionBody>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header onClick={() => toggleInnerAccordionItem("1")}>
              Seção de Configurações Padrão de Tickets
            </Accordion.Header>
            <AccordionBody>
              <Image
                rounded
                className="mb-4 ms-2"
                src={Img18}
                alt="Seção Mapeamento padrão de atributos"
                style={{ cursor: 'pointer', width: '6vw', height: '60px', border: '1px solid #CCCCCB' }}
                onClick={() => handleImageClickLg(Img18)}
              />
              <p>
                A coluna <strong> Tipo de Monitor</strong> exibe todos os tipos de monitor no Datto RMM e uma opção para configuração
                de tickets.
              </p>
              <p>
                Para cada tipo de monitor você pode definir os valores
                padrão nos tickets de alerta do MSP Desk
                criados a partir dos alertas desse tipo de monitor:
              </p>
              <p className="fs-7 mb-3">
                Remover selecionados
              </p>
              <p className="mb-3">
                Para habilitar esse botão, selecione um ou mais mapeamentos existentes. Você pode clicar em <strong> Remover selecionados </strong>
                para remover os mapeamentos selecionados. Caso você queira apagar todos os mapeamentos existentes, clique no caixa de seleção
                no cabeçalho da tabela para selecionar todos os mapeamentos.
              </p>
              <Image
                rounded
                className="mb-3 ms-2 w-xl-13 w-xs-50"
                src={RemoverSelecionados}
                alt="Botão Remover selecionados"
                style={{ cursor: 'pointer', height: '55px', }}
              />
              <Table className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`} striped bordered hover>
                <thead>
                  <tr>
                    <th>Configuração no Datto RMM</th>
                    <th>Resultado no MSP Desk</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        Coluna de <strong> Grupo de serviço</strong>
                      </p>
                    </td>
                    <td>Define os valores padrão para o campo <strong> Grupo de serviço</strong> em tickets do MSP Desk</td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        Coluna de <strong> Catálogo de Serviço</strong>
                      </p>
                    </td>
                    <td>Define os valores padrão para o campo <strong> Catálogo de serviço</strong> em tickets do MSP Desk.
                      <CardNote
                        text={`Apenas o campo <strong> Catálogo de serviço</strong> aceita valores para pesquisa. Outros itens utilizam campos de seleção.`}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Coluna de <strong> Tipo de Ticket</strong>
                    </td>
                    <td>Define os valores padrão para o campo <strong> Tipo de Ticket</strong> em tickets do MSP Desk.</td>
                  </tr>
                  <tr>
                    <td>Coluna de <strong> Agente</strong>.</td>
                    <td>Define os valores padrão para o campo <strong> Agente</strong> em tickets do MSP Desk.</td>
                  </tr>
                </tbody>
              </Table>
              <p>
                Os menus suspensos listam todos os valores ativos conforme configurado no MSP Desk.
                Use os menus suspensos para fazer seleções.
                Você pode usar os mesmos métodos na linha <strong> Aplicar a Todas as Linhas </strong>
                no topo da lista para definir a mesma seleção em todas as linhas correspondentes.
              </p>
              <p>
                Para salvar essas configurações, clique em <strong> Salvar</strong> no canto inferior direito ou inferior direito da página.
              </p>
            </AccordionBody>
          </Accordion.Item>
        </Accordion>
        <CardNote
          text={`Recomendamos que você revise suas configurações de tickets após cada lançamento do Datto RMM para garantir que seus tipos de
              monitoramento estejam devidamente configurados.`}
        />
        <CardNote
          text={`Caso um novo tipo seja introduzido no Datto RMM, a criação do ticket falhará e será enviado um e-mail para o administrador de sua equipe
              informando que o novo tipo foi introduzido ao Datto e necessita de reconfiguração de mapeamento no MSP Desk.`}
        />
        <CardNote
          text={`Se um monitor estiver configurado para criar um ticket quando um alerta for gerado, mas o dispositivo não puder ser sincronizado
              com o
              MSP Desk (por exemplo o tipo de dispositivo não foi mapeado para o tipo de ativo ou a sincronização do ativo não estiver ativa, etc.)
              O MSP Desk tentará a sincronização do dispositivo e tentará associar o ticket ao dispositivo.
              Se o dispositivo não conseguir
              sincronizar dentro desse período, um ticket ainda será criado para a organização correspondente no MSP Desk contendo o nome, o UID
              do dispositivo e um link para o dispositivo no Datto RMM.`}
        />
        <CardNote
          text={`Se a criação do ticket falhar (por exemplo, devido a alterações feitas em credenciais, mapeamentos não feitos ou feitos de forma errada,
              configurações de segurança, etc.), um e-mail de notificação será enviado para o endereço de e-mail definido nas configurações da organização
              no MSP Desk. Se nenhum endereço de e-mail configurado em <strong> Configuração &gt; Minha empresa &gt; Campo E-mail</strong> estiver definido,
              o e-mail de falha o ticket não será enviado e, portanto você não será notificado das falhas que podem acontecer.
              O e-mail de notificação especificará a mensagem de erro e o motivo do erro.`}
        />
      </AccordionBody>
      <Modal
        show={modalShow}
        onHide={handleCloseModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClick={handleCloseModal}
      >
        <Modal.Body onClick={(e) => e.stopPropagation()}>
          <img
            src={selectedImage}
            alt="Imagem Ampliada"
            style={{ width: '100%', maxHeight: '80vh' }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalLg"
        show={modalShowLg}
        onHide={handleCloseModalLg}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClick={handleCloseModalLg}
      >
        <Modal.Body onClick={(e) => e.stopPropagation()}>
          <img
            className="w-xs-70"
            src={selectedImage}
            alt="Imagem Ampliada"
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
        </Modal.Body>
      </Modal>
    </Accordion.Item>
  )
}