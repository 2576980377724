import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Partners from './Partners';
import Processes from './Processes';
import Services from './Services';
import Testimonial from './Testimonial';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import { Helmet } from 'react-helmet-async';

const Landing = () => {
  return (
    <>
      <Helmet>
        <title>MSP Desk</title>
        <meta name="description" content="A solução completa para organizar seu atendimento. Facilite a gestão dos chamados de suporte e aumente a eficiência da sua equipe técnica com o MSP Desk." />
        <meta name="keywords" content="servicedesk, service desk, helpdesk, help desk, MSP, MSP DESK, mspdesk, msp desk, gestão de atendimentos, gestão de chamados, atendimento" />
        <link rel="canonical" href="https://www.mspdesk.com.br/" />
      </Helmet>

      <NavbarStandard />
      <Hero />
      <Services />
      <Processes />
      <Testimonial />
      <Cta />
      <Partners />
      <FooterStandard />
    </>
  );
};

export default Landing;
