import React from 'react';
import { Card } from 'react-bootstrap';

const CardNote = ({ text }) => {
  return (
    <Card className="ms-2 mb-3 h-sm-100 mt-2" style={{ borderLeft: '5px solid blue' }}>
      <Card.Body>
        <Card.Title style={{ color: '#0E05FD' }}>NOTA</Card.Title>
        <Card.Text dangerouslySetInnerHTML={{ __html: text }} />
      </Card.Body>
    </Card>
  );
};

export default CardNote;
