import React, { useState } from 'react';
import { Card, Col, Form, FormCheck, Row, Spinner, Nav, Tab } from 'react-bootstrap';
import PricingDefaultCard from '../../../components/pages/pricing/pricing-default/PricingDefaultCard';
import useFakeFetch from 'hooks/useFakeFetch';
import { pricingData, pricingAltData } from '../../../data/pricing';
import SectionHeader from './SectionHeader';
import Section from 'components/common/Section';

const pricingDataTalks = [
  {
    id: 1,
    title: 'Essential',
    subTitle: 'Ideal para pequenas empresas iniciando sua jornada de atendimento',
    price: '387',
    subscription: 'mês',
    url: '/e-commerce/billing',
    buttonText: 'Começar agora',
    isFeatured: false,
    featureTitle: 'Descrição:',
    features: [
      /* { id: 1, title: 'Possui versão mobile' }, */
      { id: 2, title: '03 usuários' },
      { id: 3, title: 'Central de Atendimento' },
      { id: 4, title: 'CRM' },
    ],
    automacao: [
      'Sequências',
      'Chatbot de Atendimento',
      'Chatbot de Automação',
    ]
  },
  {
    id: 2,
    title: 'Pro',
    subTitle: 'Para empresas que buscam mais recursos de automação',
    price: '487',
    subscription: 'mês',
    url: '/e-commerce/billing',
    buttonText: 'Começar agora',
    isFeatured: false,
    featureTitle: 'Descrição:',
    features: [
      /* { id: 1, title: 'Possui versão mobile' }, */
      { id: 2, title: '05 usuários' },
      { id: 3, title: 'Central de Atendimento' },
      { id: 4, title: 'CRM' },
      { id: 5, title: 'Disparo de Mensagem' },
    ],
    automacao: [
      'Sequências',
      'Chatbot de Atendimento',
      'Chatbot de Automação',
    ]
  },
  {
    id: 3,
    title: 'Plus+',
    subTitle: 'Solução completa para empresas em crescimento',
    price: '787',
    subscription: 'mês',
    url: '/e-commerce/billing',
    buttonText: 'Começar agora',
    isFeatured: false,
    featureTitle: 'Descrição:',
    features: [
      /* { id: 1, title: 'Possui versão mobile' }, */
      { id: 2, title: '10 usuários' },
      { id: 3, title: 'Central de Atendimento' },
      { id: 4, title: 'CRM' },
      { id: 5, title: 'Disparos de Mensagem' },
      { id: 6, title: 'Distribuição automática de atendimento' },
      { id: 7, title: 'Carteiras de Cliente' },
    ],
    automacao: [
      'Sequências',
      'Chatbot de Atendimento',
      'Chatbot de Automação',
      'Integrações (Webhook + API)'
    ]
  },
  {
    id: 4,
    title: 'Advanced',
    subTitle: 'Para empresas que necessitam de mais escalabilidade',
    price: '1.187',
    subscription: 'mês',
    url: '/e-commerce/billing',
    buttonText: 'Começar agora',
    isFeatured: false,
    featureTitle: 'Descrição:',
    features: [
      /* { id: 1, title: 'Possui versão mobile' }, */
      { id: 2, title: '20 usuários' },
      { id: 3, title: 'Central de Atendimento' },
      { id: 4, title: 'CRM' },
      { id: 5, title: 'Disparos de Mensagem' },
      { id: 6, title: 'Distribuição automática de atendimento' },
      { id: 7, title: 'Carteiras de Cliente' },
    ],
    automacao: [
      'Sequências',
      'Chatbot de Atendimento',
      'Chatbot de Automação',
      'Integrações (Webhook + API)'
    ]
  }
];

const TabbedPricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const { loading: priceLoading, data: pricing } = useFakeFetch(
    isMonthly ? pricingData : pricingAltData,
    1000
  );

  const handleToggle = () => {
    setIsMonthly(prevState => !prevState);
  };

  return (
    <section>
      <SectionHeader
        id="precos"
        title="Planos flexíveis"
        subtitle="Oferecemos planos flexíveis e preços personalizados que se ajustam perfeitamente à sua necessidade de usuários, garantindo que você pague somente pelo que realmente utiliza"
      />

      <Tab.Container defaultActiveKey="msp-desk">
        <Nav variant="tabs" className="justify-content-center mb-4">
          <Nav.Item>
            <Nav.Link eventKey="msp-desk">MSP Desk</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="msp-talks" className="position-relative">
              MSP Talks
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                New
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="msp-desk">
            <Row className="justify-content-end align-items-center me-9">
              <Col xs="auto">
                <FormCheck.Label htmlFor="custom-switch" className="me-3 mb-0">
                  Anual
                </FormCheck.Label>
                <Form.Check type="switch" className="mb-0 d-inline-block">
                  <Form.Check.Input
                    type="checkbox"
                    className="pe-0"
                    checked={isMonthly}
                    onChange={handleToggle}
                  />
                </Form.Check>
                <FormCheck.Label className="ms-2 mb-0">Mensal</FormCheck.Label>
              </Col>
            </Row>

            <Row className="g-0 justify-content-center">
              {priceLoading ? (
                <Col xs={12} className="text-center py-4">
                  <Spinner animation="grow" />
                </Col>
              ) : (
                pricing.map(pricing => (
                  <PricingDefaultCard key={pricing.id} pricing={pricing} />
                ))
              )}
            </Row>
          </Tab.Pane>

          <Tab.Pane eventKey="msp-talks">
            <Section style={{ padding: "0" }}>
              <Row className="g-0 justify-content-center">
                {pricingDataTalks.map(pricing => (
                  <PricingDefaultCard key={pricing.id} pricing={pricing} />
                ))}
              </Row>
            </Section>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </section>
  );
};

export default TabbedPricing;
