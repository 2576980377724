import { Accordion, Card, Image, Modal, Table } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import Img16 from "../../../../assets/img/integration-modals/Alertas16.png";
import Webhook from "../../../../assets/img/integration-modals/Webhook.png";
import GerarSenha from "../../../../assets/img/integration-modals/GerarSenha.png";
import ModalWebhook from "../../../../assets/img/integration-modals/ModalWebhook.png";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useState } from "react";
import CardNote from "../CardNote";

export default function AlertPriorityMapAccordion({ activeKeys, toggleAccordionItem }) {

  const [modalShow, setModalShow] = useState(false);
  const [modalShowLg, setModalShowLg] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const {
    config: { isDark }
  } = useAppContext();

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalShow(true);
  };
  const handleImageClickLg = (image) => {
    setSelectedImage(image);
    setModalShowLg(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };
  const handleCloseModalLg = () => {
    setModalShowLg(false);
  };

  return (
    <Accordion.Item eventKey="3">
      <Accordion.Header onClick={() => toggleAccordionItem("3")}>
        <p className="m-0 fs-7">Configurar o Mapeamento de Prioridade de Alertas</p>
      </Accordion.Header>
      <AccordionBody>
        <p className="mb-2">
          As regras de alerta permitem que você gerencie os alertas do Datto RMM e seus respectivos tickets de alerta
          no MSP Desk. No cartão de configuração da integração, comece configurando o mapeamento de prioridade de
          alerta na aba <strong> Alertas</strong>.
        </p>
        <Image
          rounded
          className="mb-3 ms-2"
          src={Img16}
          alt="Aba Alertas"
          style={{ cursor: 'pointer', width: '10vw', height: '70px', border: '1px solid #CCCCCB' }}
          onClick={() => handleImageClick(Img16)}
        />
        <p className="mb-3">
          Você pode ativar ou desativar a alternância <strong> Sincronizar Alertas</strong> a qualquer momento para controlar se a
          sincronização de alertas está ativa ou não. Para salvar sua configuração selecionada clique em <strong> Salvar</strong> no
          canto superior direito.
        </p>
        <CardNote
          text={`Você pode desejar manter essa alternância desativada enquanto trabalha na sua configuração e salva suas alterações. Depois,
              você pode ativar a alternância sempre que estiver pronto para iniciar definitivamente a sincronização de tickets com suas
              atualizações.`}
        />
        <p className="fs-7 mb-3">
          Configuração de Webhook
        </p>
        <p className="mb-3">
          Você pode clicar em <strong> Configuração de Webhook</strong> para abrir um modal de configuração do webhook do Datto RMM.
        </p>
        <Image
          rounded
          className="mb-3 ms-2 w-xl-13 w-xs-50"
          src={Webhook}
          alt="Botão Configuração de Webhook"
          style={{ cursor: 'pointer', height: '55px', }}
        /* onClick={() => handleImageClick(Webhook)} */
        />
        <p className="mb-3">
          Este modal contém as informações para configurar o Webhook no Datto RMM.
        </p>
        <Image
          rounded
          className="mb-3 ms-2"
          src={ModalWebhook}
          alt="Modal Configuração de Webhook"
          style={{ cursor: 'pointer', width: '10vw', height: '70px', border: '1px solid #CCCCCB' }}
          onClick={() => handleImageClickLg(ModalWebhook)}
        />
        <p className="mb-3">
          Caso queira configurar o envio de webhooks de forma global navegue até <strong> Setup &gt; Integrations &gt; Global Webhooks</strong> e habilite o envio
          seguindo os passos à seguir, caso contrário navegue até  a política de monitoramento desejada e siga as instruções.<br /><br />
          Escolha quais prioridades de alerta devem ser enviadas
          pelo webhook. Depois, insira a <strong> URL</strong> e o <strong> Content-Type</strong> evidenciados no modal do MSP Desk. Por fim, na parte
          inferior da página poderá adicionar o <strong> Payload</strong> em formato JSON.<br /><br />
          Nessa seção você pode copiar o payload apresentado no MSP Desk e colá-lo no campo do Datto. Atente-se de mudar a propriedade <strong> "state"</strong>
          com sua senha que pode ser gerada na seção abaixo no MSP Desk clicando no botão <strong> Gerar</strong>.
        </p>
        <Image
          rounded
          className="mb-3 ms-2"
          src={GerarSenha}
          alt="Seção Gerar uma senha"
          style={{ cursor: 'pointer', width: '35vw', height: '80px', border: '1px solid #CCCCCB' }}
          onClick={() => handleImageClick(GerarSenha)}
        />
        <CardNote
          text={`OS payloads de alertas resolvidos devem ter a propriedade <strong> alert_resolved</strong> definida como <strong> true</strong>.`}
        />
        <CardNote
          text={`Para usar uma tradução em Francês, Italiano, Alemão, Inglês ou Português, susbtitua o campo <strong> alert_message</strong> por uma das
              seguintes opções: <strong> alert_message_fr</strong>, <strong> alert_message_it</strong>, <strong> alert_message_de</strong>,
              <strong> alert_message_en</strong> ou <strong> alert_message_pt</strong>.`}
        />
        <p className="fs-7 mb-3">
          Seleção de prioridades de alerta
        </p>
        <p className="mb-2">
          Você pode selecionar os valores padrão para a prioridade do ticket que será mapeada do Datto RMM para o MSP Desk, da seguinte forma:
        </p>
        <Table className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`} striped bordered hover>
          <thead>
            <tr>
              <th>Campo</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Prioridade do Datto RMM</td>
              <td>
                Exibe as prioridades dos alertas que são gerados no Datto RMM. As prioridades são atribuídas por meio de monitores.
              </td>
            </tr>
            <tr>
              <td>Prioridade do MSP Desk</td>
              <td>
                Selecione uma prioridade de ticket do MSP Desk no menu suspenso para mapeá-la à prioridade de alerta correspondente do
                Datto RMM.
              </td>
            </tr>
          </tbody>
        </Table>
        <p className="mb-3">
          Para salvar este mapeamento, clique em Salvar no canto inferior direito da página.
        </p>
        <p className="mb-3">
          Em seguida, você configurará as configurações de tickets na aba <strong> Definições de Ticket</strong>.
        </p>
      </AccordionBody>
      <Modal
        show={modalShow}
        onHide={handleCloseModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClick={handleCloseModal}
      >
        <Modal.Body onClick={(e) => e.stopPropagation()}>
          <img
            src={selectedImage}
            alt="Imagem Ampliada"
            style={{ width: '100%', height: 'auto' }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalLg"
        show={modalShowLg}
        onHide={handleCloseModalLg}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClick={handleCloseModalLg}
      >
        <Modal.Body onClick={(e) => e.stopPropagation()}>
          <img
            src={selectedImage}
            alt="Imagem Ampliada"
            style={{ width: '100%', height: '80vh' }}
          />
        </Modal.Body>
      </Modal>
    </Accordion.Item>
  )
}