import Section from "components/common/Section";
import SectionHeader from "../../landing/SectionHeader";
import { useAppContext } from "providers/AppProvider";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { GetStartedAccordion } from "./GetStartedAccordion";
import { MapClientsAccordion } from "./MapClientsAccordion";
import SyncDevicesAccordion from "./SyncDevicesAccordion";
import AlertPriorityMapAccordion from "./AlertPriorityMapAccordion";
import TicketConfigAccordion from "./TicketConfigAccordion";

const DattoIntegrationContent = () => {

  const [activeKeys, setActiveKeys] = useState([]);

  const {
    config: { isDark }
  } = useAppContext();

  const toggleAccordionItem = (key) => {
    setActiveKeys(prevKeys =>
      prevKeys.includes(key) ?
        prevKeys.filter(k => k !== key)
        : [...prevKeys, key]
    );
  };

  return (
    <Section
      id="inicio"
      className={`${isDark ? "bg-dark" : "bg-light"} py-0 overflow-hidden mr-1`}
      data-bs-theme={isDark ? "dark" : "light"}
      position="center bottom"
      overlay
      style={{ minHeight: '100vh', overflowY: 'auto' }}
    >
      <Row className="justify-content-center align-items-center pt-8 pb-lg-4 pb-xl-0">
        <Col md={12} lg={12} xl={12} className="pb-4 pb-xl-2 text-center">
          <SectionHeader
            title={'Integração com o Datto RMM'}
          /* subtitle={'Documentação da integração'} */
          />
        </Col>
        <Col>
          <p className="fs-6 mb-3">
            Sobre a integração
          </p>
          <p>
            A integração do MSP Desk e Datto RMM introduz uma plataforma de gestão empresarial verdadeiramente
            unificada, apresentando o seguinte:
          </p>
          <ul>
            <li>
              <p>
                As organizações do MSP Desk podem ser mapeadas para um ou vários sites do Datto RMM.
              </p>
            </li>
            <li>
              <p>
                Cada tipo de monitor podem ser configurados para criar tickets no MSP Desk filas designadas com padrões configuráveis de grupo de serviço, catálogo de serviço, tipo de ticket e usuário
                ticket Os padrões podem ser substituídos para monitores específicos.
              </p>
            </li>
            <li>
              <p>
                Os tipos de dispositivos do Datto RMM podem ser mapeados para tipos de ativos do MSP Desk.
              </p>
            </li>
            <li>
              <p>
                Os dispositivos do Datto RMM podem ser mapeados para ativos do MSP Desk.
              </p>
            </li>
            <li>
              <p>
                Você pode simplificar seu fluxo de trabalho fazendo com que seu ticket de alerta do MSP Desk seja automaticamente
                resolvido quando o alerta correspondente do Datto RMM for resolvido. Você também pode especificar quando um
                alerta do Datto RMM deve ser considerado repetido ou relacionado a outro alerta.
              </p>
            </li>
          </ul>
          <Card className="mb-2 col-xl-12 ms-3" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
            <Card.Body style={{ padding: '10px' }}>
              <Card.Title className="text-danger">Importante</Card.Title>
              <Card.Text>
                Recomendamos que apenas uma conta do Datto RMM seja sincronizada com a sua conta do MSP Desk.
                Conectar mais de uma conta do Datto RMM poderá acarretar em erros no funcionamento da integração.
              </Card.Text>
            </Card.Body>
          </Card>
          <p className="fs-6 mb-3 mt-3">
            Como fazer a integração?
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col md={12} lg={12} xl={12}>
          <Accordion activeKey={activeKeys} className="col-lg-12">
            <GetStartedAccordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
            <MapClientsAccordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
            <SyncDevicesAccordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
            <AlertPriorityMapAccordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
            <TicketConfigAccordion activeKeys={activeKeys} toggleAccordionItem={toggleAccordionItem} />
          </Accordion>
        </Col>
      </Row>
    </Section>
  );
};

export default DattoIntegrationContent;
