import { Accordion, Card, Col, Image, Modal, Row, Table } from "react-bootstrap";
import Img05 from "../../../../assets/img/integration-modals/05.png";
import Img12 from "../../../../assets/img/integration-modals/12.png";
import Img13 from "../../../../assets/img/integration-modals/13.png";
import Img14 from "../../../../assets/img/integration-modals/14.png";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useAppContext } from "providers/AppProvider";
import { BsCheckCircle } from "react-icons/bs";
import { useState } from "react";
import CardNote from "../CardNote";

export function MapClientsAccordion({ activeKeys, toggleAccordionItem }) {

  const [selectedImage, setSelectedImage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const {
    config: { isDark }
  } = useAppContext();


  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header onClick={() => toggleAccordionItem("1")}>
        <p className="m-0 fs-7">Configurar mapeamento de cliente (site)</p>
      </Accordion.Header>
      <AccordionBody>
        <Col>
          <p>Após habilitar a integração, o próximo passo é mapear seus clientes do MSP Desk para os
            sites do Datto RMM. No card de Configuração de Integração, clique na aba <strong>Sites</strong>:
          </p>
          <Image
            rounded
            className="mb-2 ms-2"
            src={Img05}
            alt="Aba Sites"
            style={{ cursor: 'pointer', width: '250px', height: '70px', border: '1px solid #CCCCCB' }}
            onClick={() => handleImageClick(Img05)}
          />
          <CardNote
            text={`Esta área permite que você configure os mapeamentos em um estado de rascunho.
                As alterações não são aplicadas até que você clique em <strong> Salvar</strong>.`}
          />
          <p className="fs-5 mb-3">Lista de mapeamentos potenciais</p>
          <Table className={isDark ? 'text-bg-dark' : 'text-bg-light'} striped bordered hover>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Ordenável?</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Site Datto RMM</td>
                <td></td>
                <td>
                  <p className="mb-4">
                    Pode exibir um site na sua conta do Datto RMM que corresponda ao nome do cliente do MSP Desk.
                    Se não houver correspondência sugerida, o campo ficará vazio.
                  </p>

                </td>
              </tr>
              <tr>
                <td>Cliente MSP Desk</td>
                <td style={{ textAlign: 'center' }}><BsCheckCircle color="green" /></td>
                <td>Exibe o Nome conforme configurado na sua conta do MSP Desk. Selecione o cliente que aparecerá na lista suspensa neste campo.
                  <CardNote
                    text={`Um cliente do MSP Desk pode ser mapeado para mais de um site do Datto RMM.
                        Para isso, selecione o mesmo cliente para o site correspondente do Datto RMM.`}
                  />
                  <p className="fs-7 mb-3">Optando por não mapear um cliente</p>
                  <p className="mb-1">
                    Para escolher não mapear um cliente basta deixar como selecionado o item "Não mapeado" para o respectivo site do Datto.
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
          <CardNote
            text={`Alterar o nome de um site do Datto RMM não renomeará o cliente mapeado do
                MSP Desk e vice-versa. O mapeamento continuará
                baseado no ID do cliente do MSP Desk.`}
          />
          <p className="fs-7 mb-4">
            Botões de ação
          </p>
          <Image
            fluid
            rounded
            className="mb-3"
            src={Img12}
            alt="Botões de ação"
            style={{ cursor: 'pointer', height: '40px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
            onClick={() => handleImageClick(Img12)}
          />
          <p className="mb-2">Os seguintes botões de ação estão disponíveis para o mapeamento:</p>
          <Table
            className={`${isDark ? "text-bg-dark" : "text-bg-light"} mb-4`}
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Botão de Ação</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sincronizar Sites</td>
                <td>
                  <div>
                    Se você deseja forçar uma sincronização de sites clique nesse botão. Uma sincronização fica disponível a cada 60 minutos.
                    <CardNote
                      text={`A sincronização dos sites acontecem todos os dias às 00:00, horário de Brasília.`}
                    />
                  </div>

                </td>
              </tr>
              <tr>
                <td>Executar uma nova análise</td>
                <td>
                  <div className="mb-2">
                    Às vezes, você pode querer reexecutar sua análise de mapeamento para um ou mais itens.
                    Esta ferramenta solicitará que o sistema corresponda os clientes do MSP Desk aos sites do
                    Datto RMM com base no nome. Uma correspondência sugerida pode ou não existir.
                  </div>
                  <Image
                    rounded
                    className="mb-3"
                    src={Img13}
                    alt="Modal executar uma nova análise"
                    style={{ cursor: 'pointer', width: '12vw', height: '65px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;' }}
                    onClick={() => handleImageClick(Img13)}
                  />
                  <ul>
                    <li><strong> Manter mapeamentos existentes e analise apenas os clientes MSP Desk não capturados.</strong></li>
                    <li><strong> Excluir todos os mapeamentos existentes e analise todos os clientes MSP Desk.</strong></li>
                  </ul>
                  <div className="mb-2">
                    Clique em <strong> Executar Análise</strong> para executar a ação ou <strong> Cancelar</strong> para fechar a caixa de diálogo.
                    Notificações pop-up indicarão quando a análise começou e foi concluída.
                  </div>
                  <Image
                    fluid
                    className="mb-3"
                    src={Img14}
                    alt="Popup de sucesso de análise"
                    style={{ cursor: 'pointer', width: '15vw', height: '65px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;', objectFit: 'contain' }}
                    onClick={() => handleImageClick(Img14)}
                  />
                </td>
              </tr>
              <tr>
                <td>Salvar</td>
                <td>
                  <div className="mb-3">
                    Se você deseja preservar todas essas alterações, clique em <strong> Salvar</strong> para enviar sua
                    configuração de mapeamento
                    para o banco de dados. Este botão não está disponível se não houver alterações não salvas.
                  </div>
                  <CardNote
                    text={`As seleções de caixas de seleção não impactam esta ação. As alterações serão salvas para todas as alterações
                        não salvas, independentemente de quais caixas de seleção estejam selecionadas, se houver.`}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Modal
          show={modalShow}
          onHide={handleCloseModal}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onClick={handleCloseModal}
        >
          <Modal.Body onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Imagem Ampliada"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Modal.Body>
        </Modal>
      </AccordionBody>
    </Accordion.Item>
  )
}