import { Accordion, Col, Card, Modal, Image } from 'react-bootstrap';
import Img01 from "../../../../assets/img/integration-modals/01.png";
import Img03 from "../../../../assets/img/integration-modals/03.png";
import Img04 from "../../../../assets/img/integration-modals/04.png";
import { useState } from "react";
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import CardNote from '../CardNote';

export function GetStartedAccordion({ activeKeys, toggleAccordionItem }) {

  const [modalShow, setModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header onClick={() => toggleAccordionItem("0")}>
        <p className="m-0 fs-7">Habilitar integração com o Datto RMM</p>
      </Accordion.Header>
      <AccordionBody>
        <Col>
          <p>Complete os seguintes passos no MSP Desk:</p>
          <ol>
            <li className="mb-2">Navegue até <strong>Configurações &gt; Integrações &gt; Datto RMM.</strong></li>
            <li className="mb-2">Na aba <strong>Credenciais de API</strong>, clique em <strong>habilitar integração.</strong></li>
            <li style={{ listStyle: 'none' }}>
              <Image
                rounded
                fluid
                className="mb-2 ms-2"
                src={Img01}
                alt="Tela para habilitar a integração"
                style={{
                  cursor: 'pointer',
                  width: '600px',
                  height: '70px',
                  border: '1px solid #CCCCCB',
                }}
                onClick={() => handleImageClick(Img01)}
              />
            </li>
            <li className="mb-2" value={3}>
              Insira seu <strong>Servidor (url)</strong>, <strong> Nome de usuário (chave) </strong> e a <strong>Senha (segredo)</strong>.
              <Card className="mb-2 col-xl-12 ms-2" style={{ margin: '10px 0', borderLeft: '5px solid red' }}>
                <Card.Body>
                  <Card.Title className="text-danger">Importante</Card.Title>
                  <Card.Text>
                    Você deve ter uma conta de nível administrador dentro do MSP Desk para evitar erros de permissões.
                  </Card.Text>
                </Card.Body>
              </Card>
            </li>
            <li className="mb-2" value={4}>Clique em <strong>Salvar e verificar conexão</strong>.</li>
            <Image
              rounded
              fluid
              className="mb-2 ms-2"
              src={Img03}
              alt="Clicar em Salvar e verificar conexão"
              style={{ cursor: 'pointer', width: '250px', height: '70px', border: '1px solid #CCCCCB' }}
              onClick={() => handleImageClick(Img03)}
            />
            <CardNote text={`Quando clicar em <strong>Salvar e verificar conexão, </strong>
                  as outras abas na integração serão desabilitadas temporariamente enquanto os dados do MSP Desk são carregados.`}
            />
            <li className="mb-3" value={5}>Com o sucesso da conexão, o botão de autenticação será desabilitado. Após a conexão ser bem sucedida, as outras abas
              serão habilitadas para configurar.</li>
            <Image
              rounded
              fluid
              className="mb-3 ms-2"
              src={Img04}
              alt="Botão desabilitado com o sucesso da conexão"
              style={{ cursor: 'pointer', width: '250px', height: '70px', border: '1px solid #CCCCCB' }}
              onClick={() => handleImageClick(Img04)}
            />
          </ol>
        </Col>
        <Modal
          show={modalShow}
          onHide={handleCloseModal}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onClick={handleCloseModal}
        >
          <Modal.Body onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Imagem Ampliada"
              style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Modal.Body>
        </Modal>
      </AccordionBody>
    </Accordion.Item>
  )
}
