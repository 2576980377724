import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Container, Nav, Navbar } from 'react-bootstrap';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import { topNavbarBreakpoint } from 'config';
import { useAppContext } from 'providers/AppProvider';
import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import IntegrationRightSideNavItem from './IntegrationRightSideNavItem';

const IntegrationNavbar = () => {
  const {
    config: { isDark }
  } = useAppContext();
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => handleNavbarTransparency(isDark);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isDark]);

  return (
    <Navbar
      style={isDark ? {} : { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px' }}
      variant={isDark ? 'light' : 'dark'}
      fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-dark': !navbarCollapsed && isDark,
        'bg-100': !navbarCollapsed && !isDark,
      })}
    >
      <Container>
        <Logo at={'navbar-top'} width={120} className="logoItem" />
        <Flex alignItems="center" className="gap-2">
          <ThemeControlDropdown dropdownClassName="d-lg-none" />
          <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        </Flex>
        <Navbar.Collapse className="scrollbar">
          <Nav>
            {/* <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} /> */}
          </Nav>
          <IntegrationRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default IntegrationNavbar;
