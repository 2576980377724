import IntegrationFooter from "../IntegrationFooter";
import IntegrationNavbar from "../IntegrationNavbar";
import DattoIntegrationContent from "./DattoIntegrationContent";
import React from "react";

export const Integration = () => {
  return (
    <>
      <IntegrationNavbar />
      <DattoIntegrationContent />
      <IntegrationFooter />
    </>
  );
};
